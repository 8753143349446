<script setup lang="ts">
</script>

<template>
  <div class="flex flex-col min-h-full">
    <layout-header class="w-full" />
    <freemium-form />

    <div class="flex-1 flex min-h-full overflow-x-auto">
      <div
        class="flex-auto 3xl:max-w-[1500px] 3xl:justify-center 3xl:items-center"
      >
        <slot />
      </div>
    </div>
    <layout-footer class="w-full" />
  </div>
</template>

<style lang="postcss">
@import "@/assets/css/main.css";
</style>
